body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

#root, html, body {
  min-height: 100%;
  width: 100%;
}

#auth-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/image/loginBackground.svg");
}

.ant-table table {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
}
.ant-table-thead > tr > th {
  background-color: #F9FAFB;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 500;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.ant-table-tbody > tr > td {
  color: #6B7280;
  font-size: 12px;
}
table tr td.ant-table-selection-column {
  padding: 16px;
}

.ant-modal-body {
  padding: 32px;
}
.ant-modal-content {
  border-radius: 4px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 30px;
  margin-bottom: 0px;
}
.ant-notification-notice {
  padding: 18px;
  border-radius: 6px;
}
.ant-notification-notice-error .ant-notification-notice-message {
  color: #9F1239;
}
.ant-notification-notice-error .ant-notification-notice-description {
  color: #FB7185;
}
.ant-notification-notice-success .ant-notification-notice-message {
  color: #008556;
}
.ant-notification-notice-success .ant-notification-notice-description {
  color: #008556;
}
.ant-notification-notice-close {
  display: none;
}
.ant-notification-notice-btn {
  margin-top: 10px;
  margin-left: 22px;
  float: unset;
}

.react-contexify__will-enter--scale {
  top: 50px !important;
}
.react-contexify__separator {
  height: 3px !important;
  background-color: #F3F4F6 !important;
}
.react-contexify__item__content:hover {
  background-color: #FFFFFF !important;
  color: #111827 !important;
}
